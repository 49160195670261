const bscTestnet = {
    id: 1996,
    name: 'Sanko',
    network: 'arb-testnet',
    iconUrl: 'https://snowtrace.io/static/media/bsc.2b5b1b5e.svg',
    iconBackground: '#f0b90b',
    nativeCurrency: {
        decimals: 18,
        name: 'DMT ',
        symbol: 'DMT',
    },
    rpcUrls: {
        default: {
            http: ['https://mainnet.sanko.xyz'],
        },
    },
    blockExplorers: {
        default: { name: 'BscScan', url: 'https://explorer.sanko.xyz/' }, // Add this block explorer
    },
    testnet: true,
};



export { bscTestnet };
