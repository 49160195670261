import React from "react";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { bscTestnet } from "./chains";
import { MyComponent } from "./MyComponent";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";  // Use Routes instead of Switch in React Router v6
import HomePage from "./Homepage"; // Import your HomePage component
import { HashRouter as Router, Route, Routes } from "react-router-dom";


// Step 1: Create the QueryClient instance
const queryClient = new QueryClient();

const { provider, chains } = configureChains(
  [bscTestnet],
  [
    jsonRpcProvider({
      rpc: chain => ({ http: chain.rpcUrls.default.http[0] }),
    }),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains}>
          <Router>
            <Routes>
              {/* Home page route */}
              <Route path="/" element={<HomePage />} />

              {/* Main app route */}
              <Route path="/app" element={<MyComponent />} />
            </Routes>
          </Router>
        </RainbowKitProvider>
      </WagmiConfig>
    </QueryClientProvider>
  );
}