import React from "react";
import "./XPWindow.css"; // Import the CSS styles
import logo from './assets/logo.png'

const XPWindow = () => {
  return (
    <div className="box-main">
      <div className="xp-window">
        {/* Title Bar */}
        <div className="xp-title-bar">
          <img src={logo} alt="keyrush icon" className="xp-icon" style={{ width: "45px" }} />
          {/* <div className="xp-title">KEYRUSH-Information</div> */}
          <div className="xp-close-btn">X</div>
        </div>

        {/* Content */}
        <div className="xp-content">
          <h2>How to play?</h2>
          <p className="margin-left">O:>> Welcome to Keyrush</p>
          

          <h3>### Game Rules and Information  ###</h3>

          <p>
            <strong>Key Purchase Mechanism</strong>
            <br />
            Each key starts at "$0.10", with the price increasing by "1%" for every key purchased and compounds.
          </p>

          <h3>#Winning the Game</h3>
          <p>
            To win, a player must purchase the last key when the timer reaches "0:00". If no purchases are made before the timer ends, the prize will be awarded to the last KEY buyer.
          </p>

          <h3>Countdown Timer</h3>
          <p>
            The game initiates with a "24-hour countdown". For the first "10 keys", the timer increases by "60 seconds" for each key bought. The pattern continues until the timer stabilizes at an increment of "30 seconds" for each key thereafter.
          </p>

          <h3>Prize Pot Distribution</h3>
          <p>
            Total Amount: The total amount spent on keys contributes to the "prize pot".<br />
            Winner's Share: "51%" of the prize pot goes to the player who purchases the last key.<br />
            <br /><br />
            - Developer Fee: "4%" of the pot is allocated to the developers.<br />
            - Buyback: "8%" of the pot is used for buying back $RUSH tokens.<br />
            - Key Holder Distribution: "37%" of the pot is distributed among key<br />
            - holders(LOSERS) based on their holdings.
          </p>
          
        </div>
        
      </div>
      <div>
      <hr className="xp-hr" />
      </div>
      

      {/* Bottom Section */}
      <div className="xp-bottom-section">
        <div className="xp-scroll-message">
          SCROLL TO PLAY !!!
          <span className="arrows">↓↓↓</span>
        </div>
        <a 
  href="https://mirror.xyz/0x618D5b09044592AfD364c4569786F0E1b7A356b0/PG9KzkV11gyke0H77JATX3HOfjlBeZqkKIKA9YUJaWM" 
  className="docs-btn" 
  target="_blank" 
//   rel="noopener noreferrer"
>
  Docs
</a>      </div>
    </div>
  );
};

export default XPWindow;
