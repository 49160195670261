import React from "react";
import XPWindow from "./xpwindow";  // Import XPWindow component
import "./LogoAndXPWindow.css";  // Add your CSS file
import logo1 from './assets/logo.png';
import mine from './assets/mine.svg'
import Alien from './assets/alien.svg'
import print from './assets/print.svg'
import Recycle from './assets/recycle.svg'
import computer from './assets/computer.svg'
// import internet from './assets/internet.svg'
import bible from './assets/bible.svg'
import x from './assets/x.svg'
import tg from './assets/tg.svg'
import c from './assets/C copy.svg'
import internet from './assets/Internet e.svg'


const LogoAndXPWindow = () => {
  return (
    <div className="main-container"> {/* Flexbox container */}
      {/* Left section for logos */}
      <div className="logo-section">
        {/* Example of an image with a link and a small text */}
        <div className="logo-item">
          <a rel="noopener noreferrer">
            <img src={mine} alt="Logo 1" className="logo" />
          </a>
          <p className="logo-text">Mine</p>
        </div>

        <div className="logo-item">
          <a rel="noopener noreferrer">
            <img src={Alien} alt="Logo 2" className="logo" />
          </a>
          <p className="logo-text">Alien</p>
        </div>

        <div className="logo-item">
          <a  rel="noopener noreferrer">
            <img src={print} alt="Logo 3" className="logo" />
          </a>
          <p className="logo-text">Print</p>
        </div>
        <div className="logo-item">
          <a  rel="noopener noreferrer">
            <img src={Recycle} alt="Logo 3" className="logo" />
          </a>
          <p className="logo-text">Recycle Bin</p>
        </div>
        <div className="logo-item">
          <a  rel="noopener noreferrer">
            <img src={computer} alt="Logo 3" className="logo" />
          </a>
          <p className="logo-text">My computer</p>
        </div>
        <br />
        <div className="logo-item">
          <a  rel="noopener noreferrer">
            <img src={logo1} alt="Logo 3" className="logo" />
          </a>
          <p className="logo-text">Key Rush</p>
        </div>
        <div className="logo-item">
          <a  rel="noopener noreferrer">
            <img src={c} alt="Logo 3" className="logo" />
          </a>
          <p className="logo-text">Local: C</p>
        </div>
        <div className="logo-item">
          <a  rel="noopener noreferrer">
            <img src={internet} alt="Logo 3" className="logo" />
          </a>
          <p className="logo-text">Internet</p>
        </div>
        <div className="logo-item">
          <a href="https://mirror.xyz/0x618D5b09044592AfD364c4569786F0E1b7A356b0/PG9KzkV11gyke0H77JATX3HOfjlBeZqkKIKA9YUJaWM" target="_blank" rel="noopener noreferrer">
            <img src={bible} alt="Logo 3" className="logo" />
          </a>
          <p className="logo-text">Bible</p>
        </div>
        <div className="logo-item">
          <a href="https://x.com/Keyrushsanko" target="_blank" rel="noopener noreferrer">
            <img src={x} alt="Logo 3" className="logo" />
          </a>
          <p className="logo-text">X.com</p>
        </div>
        <br />
        <div className="logo-item">
          <a href="https://t.me/Keyrushpaytobeat" target="_blank" rel="noopener noreferrer">
            <img src={tg} alt="Logo 3" className="logo" />
          </a>
          <p className="logo-text">Telegram</p>
        </div>
        {/* Add more logos as needed */}
      </div>

      {/* Right section for the XPWindow component */}
      <div className="xp-window-section">
        <XPWindow />
      </div>
    </div>
  );
};

export default LogoAndXPWindow;
