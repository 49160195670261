import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import "./Homepage.css";  // Make sure this file is properly named
import logo from './assets/logo.png'

export const HomePage = () => {
  const navigate = useNavigate();

  // Function to handle the Start button click
  const handleStart = () => {
    navigate("/app");  // Navigate to the main app
  };

  return (
    <div className="home-container">
      {/* Logo section */}
      <div className="logo-section">
        {/* <img src={} alt="Logo" className="logo-image" /> */}
        
        {/* <img src={logo} alt="Key Icon" className="key-icon" /> */}
      </div>
      <h1 className="title">Key Rush inc</h1>

      {/* Start Button */}
      <button className="start-button" onClick={handleStart}>Start</button>
    </div>
  );
};

export default HomePage;
