import React, { useState, useEffect } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useSigner, useProvider } from "wagmi";
import { ethers } from "ethers";
import CryptoJS from "crypto-js";
import PayToBeatABI from './contract/lottery.json';
import XPWindow from "./xpwindow";
import bg from './assets/bg2.png'
import LogoAndXPWindow from './LogoAndXPWindow'
import './mycomponent.css'
import logo from './assets/logo.png'
import GameLayout from "./GameLayout";
import './GameLayout.css'
import music from './assets/music.mp3'


// Contract address (replace with your deployed contract address)
const contractAddress = "0x11A4Ca2Cb83605dB3Ba1861341767B68E6FEc484";

export const MyComponent = () => {
  const { address, isConnected } = useAccount();
  const { data: signer } = useSigner();
  const provider = useProvider();

  const [keyPrice, setKeyPrice] = useState("0");
  const [timeRemaining, setTimeRemaining] = useState(0);  // Time remaining in seconds from the contract
  const [keysOwned, setKeysOwned] = useState(0);
  const [keyCount, setKeyCount] = useState(0);
  const [numKeysToBuy, setNumKeysToBuy] = useState(1);
  const [referrerAddress, setReferrerAddress] = useState(null);
  const [totalCost, setTotalCost] = useState("0");
  const [userReferralLink, setUserReferralLink] = useState("");
  const [lastKeyHolder, setLastKeyHolder] = useState("");
  const [totalETH, setTotalETH] = useState("0");
  const [copySuccess, setCopySuccess] = useState(false);
  const [userBalance, setUserBalance] = useState("0");
  const [showPopup, setShowPopup] = useState(false);
  const [totalTimeExtended, setTotalTimeExtended] = useState(0);





  let contract;

  // Initialize contract with signer if connected, otherwise use provider
  const initializeContract = () => {
    if (signer) {
      contract = new ethers.Contract(contractAddress, PayToBeatABI, signer);
    } else if (provider) {
      contract = new ethers.Contract(contractAddress, PayToBeatABI, provider);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(userReferralLink).then(() => {
      setCopySuccess(true);
      // Hide the message after 2 seconds
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };


  // Load contract data (including remaining time in seconds)
  const loadContractData = async () => {
    try {
      initializeContract();
      if (!contract) {
        console.error("Contract is not initialized.");
        return;
      }

      const price = await contract.currentKeyPrice();
      const remainingTime = await contract.getTimeRemaining();  // Remaining time directly from contract
      const totalKeys = await contract.keyCount();
      const lastKeyHolderAddress = await contract.lastKeyHolder();
      const totalETHBalance = await provider.getBalance(contractAddress);
      const totalExtended = await contract.totaltimextended(); // Fetch the total time extended value


      setKeyPrice(ethers.utils.formatEther(price));
      setTimeRemaining(parseInt(remainingTime));  // Set remaining time in seconds
      setKeyCount(totalKeys.toNumber());
      setLastKeyHolder(lastKeyHolderAddress);
      setTotalETH(ethers.utils.formatEther(totalETHBalance));
      setTotalTimeExtended(totalExtended.toNumber());

      // Fetch user-specific data only if wallet is connected
      if (isConnected && address) {
        const keys = await contract.keysOwned(address);
        setKeysOwned(keys.toNumber());
      }
    } catch (err) {
      console.error("Error loading contract data:", err);
    }
  };

  useEffect(() => {
    loadContractData(); // Load contract data regardless of connection
  }, [provider, signer]);

  useEffect(() => {
    if (isConnected && address) {
      const fetchUserBalance = async () => {
        const balance = await provider.getBalance(address);
        setUserBalance(ethers.utils.formatEther(balance));
      };
      fetchUserBalance();
    }
  }, [isConnected, address, provider]);
  

  // Hash the address using SHA-256
  const hashAddress = (address) => {
    return CryptoJS.SHA256(address).toString();
  };

  // Generate referral link with hashed address (instead of encryption)
  const generateReferralLink = () => {
    if (address) {
      const hashedAddress = hashAddress(address); // Hash the address
      const link = `${window.location.origin}/?ref=${encodeURIComponent(hashedAddress)}`;
      setUserReferralLink(link);
    }
  };

  const calculateTotalCost = async () => {
    initializeContract();
    try {
      const currentPrice = await contract.currentKeyPrice();
      let totalCost = ethers.BigNumber.from(0);
      let tempKeyPrice = currentPrice;

      for (let i = 0; i < numKeysToBuy; i++) {
        totalCost = totalCost.add(tempKeyPrice);
        tempKeyPrice = tempKeyPrice.mul(100 + 1).div(100);
      }

      setTotalCost(ethers.utils.formatEther(totalCost));
    } catch (err) {
      console.error("Error calculating total cost:", err);
    }
  };

  const purchaseKeys = async () => {
    try {
      initializeContract();
      const currentPrice = await contract.currentKeyPrice();
      let totalCost = ethers.BigNumber.from(0);
      let tempKeyPrice = currentPrice;
  
      for (let i = 0; i < numKeysToBuy; i++) {
        totalCost = totalCost.add(tempKeyPrice);
        tempKeyPrice = tempKeyPrice.mul(100 + 1).div(100);
      }
  
      const tx = await contract.purchaseKeys(numKeysToBuy, referrerAddress || ethers.constants.AddressZero, {
        value: totalCost
      });
      
      // Show the popup when the transaction is submitted
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000); // Hide the popup after 3 seconds
  
      await tx.wait();
  
      loadContractData();
    } catch (err) {
      console.error("Error purchasing keys:", err);
    }
  };
  

  // Countdown logic for timer
  useEffect(() => {
    const interval = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining((prev) => prev - 1);  // Reduce time by 1 second every interval
      }
    }, 1000);

    return () => clearInterval(interval);  // Clear interval when the component unmounts
  }, [timeRemaining]);

  // Format time into days, hours, minutes, seconds
  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${days}d ${hours}h ${minutes}m ${secs}s`;
  };

  useEffect(() => {
    if (isConnected) {
      calculateTotalCost();
    }
  }, [numKeysToBuy, keyPrice, isConnected]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const refHash = queryParams.get("ref");

    if (refHash) {
      try {
        setReferrerAddress(refHash);  // Use the hashed referral address
      } catch (err) {
        console.error("Failed to handle referral address:", err);
      }
    }

    if (isConnected) {
      generateReferralLink();
    }
  }, [isConnected]);

  return (
    <div style={{
      width: "auto",
      height: "200vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      fontFamily: "Arial, sans-serif",
      backgroundImage: `url(${bg})`,  // Set background image here
      backgroundSize: "cover",  // Ensure the image covers the entire area
      backgroundPosition: "center"
    }}>
      <audio src={music} autoPlay loop />

      {showPopup && (
      <div style={{
        position: 'fixed',
        top: '20px',
        right: '20px',
        padding: '15px 20px',
        backgroundColor: '#28a745',
        color: 'white',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        transition: 'opacity 0.3s ease-in-out',
      }}>
        Transaction Submitted!
      </div>
    )}
      
      <LogoAndXPWindow />
      <div className="layout">
        <div className="xp-title-bar">
          <img src={logo} alt="keyrush icon" className="xp-icon" style={{ width: "45px" }} />
          <div className="xp-title">KEYRUSH-PAY-TO-BEAT</div>
          <div className="xp-close-btn">X</div>
        </div>
        <div className="header-row">
          {/* Countdown Section */}
          <div className="countdown-section">
              <div className="countdown-title">COUNTDOWN</div>
              <div className="countdown-timer">{formatTime(timeRemaining)}</div> {/* Display the formatted countdown timer */}
          </div>

        {/* Reward Distribution Section */}
        <div className="reward-distribution-section">
         <ul>
            <li>Winner <span>51%</span></li>
            <li>Buy back <span>8%</span></li>
            <li>Redistribution <span>37%</span></li>
            <li>Dev Fee <span>4%</span></li>
        </ul>
      </div>

      {/* Connect Wallet Section */}
      <div className="connect-wallet-section">
       <ConnectButton  className="connect-wallet-btn" />
      </div>
        </div>
        <div className="game-container">
      {/* Left Section: Game Details */}
      <div className="game-details-section">
        <h3 className="section-title">Game Details (WIN DMT)</h3>
        <div className="details-list">
          <p>Current KEY Price: <span>{keyPrice} DMT</span></p>
          <p>Current Pot Size: <span>{totalETH} DMT</span></p>
          <p>Compound Rate (KEY): <span>1.00%</span></p>
          <p>KEY Counter: <span>{keyCount}</span></p>
          <p>Last Key Holder: <span>{lastKeyHolder}</span></p>
        </div>
        
        <div className="user-stats">
          <p>MY KEYS: <span>{keysOwned}</span></p>
          {/* <p>TOTAL SPENT: <span>20$</span></p> */}
          <p>Total Time Extended: <span>{totalTimeExtended} seconds</span></p> {/* Display total time extended */}
        </div>
        
        <div className="game-description">
          <p>#Join the race against time: Buy a key, add some seconds, and let the countdown begin!</p>
          <p>#Buy your keys wisely—when the clock strikes zero, will you be the lucky one?</p>
        </div>
      </div>

      {/* Right Section: Start Playing */}
      <div className="start-playing-section">
  <div className="start-playing-header">START PLAYING...</div>
  
  <div className="input-fields">
    <label>Enter Amount (KEYS)</label>
    <input
              type="number"
              value={numKeysToBuy}
              onChange={(e) => setNumKeysToBuy(parseInt(e.target.value) || 1)}
              min="1"
            />
    
    <label>Total Spent</label>
    <input type="text" disabled value={totalCost}  />

    <label>Estimated Time Extension</label>
    <input type="text" value={numKeysToBuy*60} disabled />
  </div>
  {/* {isConnected && ( */}
  <button 
  className="buy-key-button" 
  onClick={purchaseKeys}
  disabled={parseFloat(userBalance) < parseFloat(totalCost) || parseFloat(totalCost) === 0 || !isConnected}
>
  {parseFloat(userBalance) < parseFloat(totalCost) ? "Insufficient Funds" : `Buy ${numKeysToBuy} Key${numKeysToBuy > 1 ? "s" : ""}`}
</button>
        {/* )} */}
  {/* <button className="buy-key-button">BUY KEY</button> */}
  </div>
    </div>

        {/* <h2>PayToBeat Game</h2>
        <h3>Current Key Price: {keyPrice} ETH</h3> */}
        
        {/* Small countdown timer */}
        
        

        {/* <h3>Total Keys Purchased: {keyCount}</h3>
        {isConnected && <h3>Your Keys Owned: {keysOwned}</h3>}
        <h3>Total ETH in Current Round: {totalETH} ETH</h3> */}

        {/* <div style={{ marginTop: "10px" }}>
          <label>
            Number of Keys to Buy:
            <input
              type="number"
              value={numKeysToBuy}
              onChange={(e) => setNumKeysToBuy(parseInt(e.target.value) || 1)}
              min="1"
              style={{
                marginLeft: "10px",
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                width: "60px"
              }}
            />
          </label>
        </div> */}

        {/* <div style={{ marginTop: "10px" }}>
          <h3>Total Cost: {totalCost} ETH</h3>
        </div> */}

        
        <h3>Your Referral Link(3% Comission):</h3>
        {/* {isConnected && ( */}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "-20px" }}>
          {/* Referral link box */}
          <p
            style={{
              backgroundColor: "#000000",
              color: "#00ff00",
              padding: "15px",
              borderRadius: "5px",
              fontSize: "14px",
              width: "60%",
              textAlign: "center",
              cursor: "pointer",
              userSelect: "none",
              transition: "background-color 0.3s",
            }}
            onClick={copyToClipboard}
          >
            {userReferralLink}
          </p>
    
          {/* Copy success message */}
          {copySuccess && (
            <div style={{ marginTop: "-10px", color: "#000000", fontSize: "20px" }}>
              Link copied to clipboard!
            </div>
          )}
        </div>
        {/* )} */}

        {/* <div style={{ marginTop: "20px", textAlign: "left" }}>
          <h3>Reward Distribution:</h3>
          <ul style={{ listStyleType: "none", padding: "0" }}>
            <li><strong>51%</strong> to Last Key Holder: {lastKeyHolder}</li>
            <li><strong>4%</strong> to Developer</li>
            <li><strong>5%</strong> to Buyback Token Address</li>
            <li><strong>37%</strong> to All Key Holders</li>
          </ul>
        </div> */}
      </div>
      {/* <div style={{ marginRight: "-1350px" }}>  {/* Apply left padding here */}
        
      {/* </div>  */}

      <div style={{ 
          marginTop: "10px", 
          padding: "10px", 
          backgroundColor: "#002b36", 
          color: "#93a1a1", 
          textAlign: "center", 
          fontSize: "20px", 
          borderTop: "3px solid #00ff00",
          borderBottom: "0px solid #00ff00",
          borderRadius: "0px",
          marginBottom: "-99px",
          width: "100%",
          fontFamily: "'Pixelify Sans', sans-serif" 
        }}>
          Decorative graphics below were not made by me and belong to their respective creators (Credits page does not exist).<br /> Created pumptuber 1998 ✧ Author - Evren
        </div>
      
    </div>
  );
};
